import { ClerkProvider, SignedIn, SignedOut } from '@clerk/nextjs';
import { CacheProvider, type EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import SignIn from 'components/SignIn';
import Loader from 'components/common/loader/Loader';
import AppContext from 'context';
import type { UserInfoResponseDto } from 'dtos';
import LogRocket from 'logrocket';
import 'moment-timezone';
import moment from 'moment-timezone';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { getUserInfo } from 'requests/user-info';
import { slk } from 'survey-core';

import { SWRConfig, type SWRConfiguration, mutate } from 'swr';

import AppContent from 'components/AppContent';
import SignedOutRedirect from 'components/layout/SignedOutRedirect';
import theme from '../theme';
import createEmotionCache from '../theme/createEmotionCache';

//globally set moment to use UTC
moment.tz.setDefault('UTC');

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_PRO_KEY as string);

slk(process.env.NEXT_PUBLIC_SURVEY_JS_KEY as string);

const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache, //for SSR this param is provided by _document.js
  pageProps,
}: AppProps & { emotionCache: EmotionCache }) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfoResponseDto | null>(null);

  useEffect(() => {
    if (router.asPath.startsWith('/login')) {
      setUserInfo(null);
      // clear swr global cache
      mutate(() => true, undefined, { revalidate: false });
    } else if (!userInfo) {
      getUserInfo().then((data) => {
        setUserInfo(data);
      });
    }
  }, [userInfo, router.asPath]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsLoading(true));
    router.events.on('routeChangeComplete', () => setIsLoading(false));
    router.events.on('routeChangeError', () => setIsLoading(false));
  }, [router]);

  if (router.asPath.startsWith('/public')) {
    return (
      <>
        {isLoading && <Loader />}
        <GlobalProviders>
          <Component {...pageProps} />
        </GlobalProviders>
      </>
    );
  }

  if (
    process.env.NEXT_PUBLIC_LOGROCKET_APP_ID &&
    typeof window !== 'undefined'
  ) {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);
  }

  return (
    <>
      <Head>
        <title>TripSuite</title>
      </Head>
      {isLoading && <Loader />}
      <AppContext.Provider value={{ ...pageProps }}>
        <ClerkProvider
          {...pageProps}
          appearance={{
            variables: { colorPrimary: theme.palette.primary.main },
          }}
        >
          <CacheProvider value={emotionCache}>
            <GlobalProviders>
              <SignedIn>
                <AppContent
                  userInfo={userInfo}
                  Component={Component}
                  pageProps={pageProps}
                />
              </SignedIn>
              <SignedOut>
                <SignedOutRedirect />
                <SignIn />
              </SignedOut>
            </GlobalProviders>
          </CacheProvider>
        </ClerkProvider>
      </AppContext.Provider>
    </>
  );
}

export default MyApp;

const GlobalProviders = ({ children }: { children: React.ReactNode }) => {
  const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        variant="success"
        hideIconVariant
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SWRConfig value={swrOptions}>{children}</SWRConfig>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
